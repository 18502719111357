/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div>
    <b-overlay
      :show="!pageIsLoaded"
      rounded="sm"
    >
      <b-row>
        <b-col md="12">
          <b-card>
            <b-card-header>
              <b-card-title>{{ $t('Konsinye Oluştur') }}</b-card-title>

            </b-card-header>

            <!-- BODY -->
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <!-- Form -->
              <b-form
                class="p-2"
                @submit.prevent="handleSubmit(saveData)"
                @reset.prevent="resetForm"
              >

                <!-- Firma ad -->
                <validation-provider
                  #default="validationContext"
                  :name="$t('Firma')"
                >
                  <b-form-group
                    :label="$t('Firma')"
                    label-for="customer_id"
                  >
                    <vue-autosuggest
                      :suggestions="customerSuggest.filteredCustomers"
                      :input-props="customerSuggest.inputProps"
                      :state="getValidationState(validationContext)"
                      :value="consignmentData.customer_name"
                      @selected="customerSelected"
                      @input="customerOnInputChanged"
                    >
                      <template slot-scope="{suggestion}">
                        <div class="d-flex align-items-center">

                          <div class="detail ml-50">
                            <b-card-text class="mb-0">
                              {{ suggestion.item.name }}
                            </b-card-text>
                            <small class="text-muted">
                              {{ suggestion.item.id }}
                            </small>
                          </div>
                        </div>
                      </template>
                    </vue-autosuggest>
                  </b-form-group>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>

                </validation-provider>
                <!-- Satış Temsilcisi -->

                <!-- Satış Temsilcisi -->
                <b-form-group

                  :label="$t('Satış Temsilcisi')"
                  label-for="user_id"
                >
                  <v-select
                    id="user_id"
                    v-model="consignmentData.consignment_user"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="usersData"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="user_id"
                  />
                </b-form-group>
                <!-- Teslim Alınacağı Tarih -->
                <b-form-group

                  :label="$t('Teslim Alınacağı Tarih')"
                  label-for="user_id"
                >
                  <b-form-datepicker
                    id="example-datepicker"
                    v-model="consignmentData.return_date"
                    class="mb-1"
                  />
                </b-form-group>
                <!-- Açıklama -->

                <b-form-group
                  :label="$t('Açıklama')"
                  label-for="description"
                >
                  <b-form-input
                    id="description"
                    v-model="consignmentData.description"
                    :label="$t('Açıklama')"
                  />

                </b-form-group>

                <b-form-group
                  :label="$t('Adres')"
                  label-for="address"
                >
                  <b-form-input
                    id="address"
                    v-model="consignmentData.address"
                    :label="$t('Adres')"
                  />

                </b-form-group>
                <b-card-body class="invoice-padding form-item-section">
                  <div
                    ref="form"
                    class="repeater-form"
                  >
                    <b-row
                      v-for="(item, index) in consignmentData.products"

                      :key="index"
                      ref="row"
                      class="pb-2"
                    >

                      <!-- Item Form -->
                      <!-- ? This will be in loop => So consider below markup for single item -->
                      <b-col
                        v-if="!item.is_deleted"
                        cols="12"
                      >

                        <!-- Form Input Fields OR content inside bordered area  -->
                        <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                        <div class="d-flex border rounded">
                          <b-row class="flex-grow-1 p-2">
                            <!-- Single Item Form Headers -->
                            <b-col
                              cols="12"
                              lg="6"
                            >
                              <b-form-group
                                :label="$t('Ürün')"
                                label-for="product"
                              >
                                <vue-autosuggest
                                  :suggestions="productSuggest.filteredProducts"
                                  :input-props="productSuggest.inputProps"
                                  :value="item.product_name"
                                  @selected="productSelected($event,index)"
                                  @input="productOnInputChanged"
                                >
                                  <template slot-scope="{suggestion}">
                                    <div class="d-flex align-items-center">
                                      <b-avatar :src="suggestion.item.image" />
                                      <div class="detail ml-50">
                                        <b-card-text class="mb-0">
                                          {{ suggestion.item.name }}
                                        </b-card-text>
                                        <small class="text-muted">
                               Stok:{{ suggestion.item.stock }}   Rezerv:{{ suggestion.item.stock_reserved }}
                                        </small>
                                      </div>
                                    </div>
                                  </template>
                                </vue-autosuggest>
                              </b-form-group></b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              <b-form-group
                                :label="$t('Miktar')"
                                label-for="quantity"
                              >
                                <b-input-group id="quantity">
                                  <b-form-input
                                    v-model="item.quantity"
                                    :label="$t('Miktar')"
                                  />
                                  <b-input-group-append is-text>
                                    {{ item.unit_name }}

                                  </b-input-group-append>
                                </b-input-group>

                              </b-form-group>
                            </b-col>

                            <b-col
                              cols="12"
                              lg="4"
                            >
                              <b-form-group
                                :label="$t('Depo')"
                                label-for="store"
                              >
                                <v-select
                                  id="store"
                                  v-model="item.store_id"
                                  :reduce="store => store.store_id"
                                  :clearable="false"
                                  label="store_name"
                                  :options="item.stores"
                                />

                              </b-form-group>
                            </b-col>

                          </b-row>
                          <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                            <feather-icon
                              size="16"
                              icon="XIcon"
                              class="cursor-pointer"
                              @click="deleteProduct(index)"
                            />

                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="d-flex align-items-center justify-content-end">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      size="sm"
                      variant="primary"
                      @click="addProduct"
                    >
                      {{ $t('Ürün Ekle') }}
                    </b-button>
                  </div>
                </b-card-body>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                  >
                    {{ $t('Kaydet') }}
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="button"
                    variant="outline-secondary"
                  >
                    {{ $t('Vazgeç') }}
                  </b-button>
                </div>

              </b-form>
            </validation-observer>
          </b-card>
        </b-col>

      </b-row></b-overlay>

  </div>
</template>

<script>
import {
  BInputGroup, BInputGroupAppend, BOverlay, BCardText, BFormDatepicker, BAvatar, BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BCardHeader, BCardTitle, BCardBody,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import router from '@/router'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'

import vSelect from 'vue-select'
import { VueAutosuggest } from 'vue-autosuggest'
import consignmentsStoreModule from '../consignmentsStoreModule'

export default {
  components: {
    BRow,
    BAvatar,
    BCard,
    BCardText,
    BForm,
    BFormGroup,
    BFormDatepicker,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BInputGroup,
    VueAutosuggest,
    BInputGroupAppend,
    vSelect,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BOverlay,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      required,

      productSuggest: {
        limit: 10,
        filteredProducts: [],
        inputProps: {
          id: 'autosuggest__input',
          class: 'form-control',
          placeholder: "Ürün Adı Giriniz'",

        },

      },
      customerSuggest: {
        limit: 10,
        filteredCustomers: [],
        inputProps: {
          id: 'customer_id',
          class: 'form-control',
          placeholder: 'Firma Adı Giriniz',

        },

      },
    }
  },

  methods: {
    deleteProduct(index) {
      this.$swal({
        title: this.$t('Emin Misiniz ?'),
        text: this.$t('Bu Ürün Kalemini Silmek İstediğinizden Emin Misiniz ? (Bu işlem geri alınamaz.)'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Silme İşlemini Onayla'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const pdata = this.consignmentData.products[index]
          if (pdata.id === 0) {
            this.consignmentData.products.splice(index, 1)
          } else {
            this.consignmentData.products[index].is_deleted = true
          }
        }
      })
    },
    saveData() {
      if (this.consignmentData.id <= 0) {
        store.dispatch('app-consignment/addConsignment', this.consignmentData)
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.showSuccessMessage()
            this.$router.push({ name: 'consignments-list' })
          })
          .catch(() => {
            this.showErrorMessage()
          })
      } else {
        store.dispatch('app-consignment/updateConsignment', this.consignmentData)
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.showSuccessMessage()
            this.$router.push({ name: 'consignments-list' })
          })
          .catch(() => {
            this.showErrorMessage()
          })
      }
    },
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
    addProduct() {
      this.consignmentData.products.push(JSON.parse(JSON.stringify(this.blankProductData)))
    },
    productSelected(option, index) {
      store.dispatch('app/fetchProduct', { id: option.item.id })
        .then(response => {
          const product = response.data.data
          const productData = this.consignmentData.products[index]
          productData.product_id = product.id
          productData.product_name = product.name
          productData.unit_name = product.unit.name
          productData.stores = product.stock_data
          productData.unit_id = product.unit.id
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < productData.stores.length; i++) {
            productData.stores[i].store_name = `${productData.stores[i].store_name} (${productData.stores[i].stock})`
          }
        })
    },
    customerSelected(option) {
      this.consignmentData.customer_id = option.item.id
      this.consignmentData.consignment_user = option.item.user_id
    },
    customerOnInputChanged(text) {
      if (text === '' || text === undefined) {
        return
      }
      store.dispatch('app/suggestCustomers', { name: text, limit: this.customerSuggest.limit })
        .then(response => {
          this.customerSuggest.filteredCustomers = [{
            data: response.data.data,
          }]
        })
    },
    productOnInputChanged(text) {
      if (text === '' || text === undefined) {
        return
      }
      store.dispatch('app/suggestProducts', { name: text, limit: this.productSuggest.limit })
        .then(response => {
          this.productSuggest.filteredProducts = [{
            data: response.data.data,
          }]
        })
    },
  },
  setup() {
    const pageIsLoaded = ref(false)
    const currencyOptions = ref([])
    const blankProductData = {
      id: 0,
      product_id: 0,
      product_name: '',
      quantity: 0,
      store_id: 0,
      description: '',
      is_deleted: false,
      stores: [],
    }
    const usersData = ref(null)
    store
      .dispatch('app/fetchUsers', {
        list_type: 'all',
      })
      .then(response => {
        const { users } = response.data.data
        const arr = []
        users.forEach(val => {
          arr.push({ label: `${val.name} (${val.email})`, value: val.id })
        })

        usersData.value = arr
      })
      .catch(() => {
      })
    const consignmentData = ref({
      id: 0,
      consignment_user: 0,
      consignment_number: '',
      description: '',
      address: '',
      customer_id: 0,
      customer_name: '',
      return_date: '',
      return_completion_date: '',
      products: [

      ],
    })

    const PRODUCT_APP_STORE_MODULE_NAME = 'app-consignment'

    // Register module
    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, consignmentsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_APP_STORE_MODULE_NAME)
    })

    const onSubmit = () => {
      store.dispatch('app-product/addProduct')
        .then(response => {
          router.push({ name: 'products-edit', params: { id: response.data.data.id } })
        })
    }

    if (router.currentRoute.params.id !== undefined) {
      store.dispatch('app-consignment/fetchConsignment', { id: router.currentRoute.params.id })
        .then(response => {
          consignmentData.value = response.data.data
          consignmentData.value.products.forEach(p => {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < p.stores.length; i++) {
              // eslint-disable-next-line no-param-reassign
              p.stores[i].store_name = `${p.stores[i].store_name} (${p.stores[i].stock})`
            }
          })
          pageIsLoaded.value = true
        })
        .catch(error => {
          if (error.response.status === 404) {
            alert('Error')
          }
        })
    } else {
      pageIsLoaded.value = true
    }
    const resetproductData = () => {

    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetproductData)
    return {
      consignmentData,
      onSubmit,
      currencyOptions,
      refFormObserver,
      getValidationState,
      pageIsLoaded,
      resetForm,
      blankProductData,
      usersData,
      priceInputConfig: {
        numeralDecimalScale: 4,
        numeral: true,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
